import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { withConsumer } from "../../helpers/CacheContext";
import Search from "./Search";
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";

const Header = ({ fetchKirbyData, fetchSOALData, location }) => {
  const [content, setContent] = useState([]);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [showMob, setShowMob] = useState(false);
  const search = useRef();

  const toggleMobMenu = () => setShowMob((prev) => !prev);
  const toggleSearch = () => {
    search.current.focus();
    setSearchBarOpen((prev) => !prev);
  };

  useEffect(() => {
    const kirbyQuery = {
      query: "site.children.listed",
      select: {
        id: true,
        title: true,
        uri: true,
        slug: true,
        pagelink: true,
        showinmenu: true,
        pagetype: true,
        resource: true,
        filtervariable: true,
        children: {
          select: {
            title: true,
            uri: true,
            pagetype: true,
            showinmenu: true,
            slug: true,
          },
        },
      },
    };

    const fetchData = async () => {
      try {
        const data = await fetchKirbyData(kirbyQuery, true);
        const initialContent = data.result;

        const promises = initialContent.map(async (element) => {
          if (element.pagetype === "link") {
            const link = element.pagelink.replace("- ", "");
            const pageData = await fetchKirbyData({
              query: `page('${link}')`,
              select: {
                id: true,
                title: true,
                uri: true,
                slug: true,
                showinmenu: true,
                pagetype: true,
                resource: true,
                filtervariable: "page.filtervariable.value",
                offerimg: "page.offerimg.toFile",
                layout: {
                  query: "page.editor.toLayouts",
                  select: {
                    columns: {
                      query: "layout.columns",
                      select: {
                        width: true,
                        span: true,
                        blocks: true,
                      },
                    },
                  },
                },
                blocks: {
                  query: "page.editor.toBlocks",
                  select: {
                    id: true,
                    type: true,
                    headline: true,
                    name: "block.name.value",
                    text: "block.text.kirbytext",
                    html: "block.toHtml",
                    table: "block.rows.toStructure",
                    image: "block.image.toFile",
                    caption: "block.caption",
                    images: {
                      query: "block.images.toFiles",
                      select: {
                        url: true,
                      },
                    },
                  },
                },
                description: "page.description.kirbytext",
                sitebar: {
                  query: "page.sitebar.toBlocks",
                  select: {
                    id: true,
                    type: true,
                    headline: true,
                    subline: true,
                    background: true,
                    name: "block.name.value",
                    text: "block.text.kirbytext",
                    html: "block.toHtml",
                    table: "block.rows.toStructure",
                    image: "block.image.toFile",
                    links: {
                      query: "block.links.toStructure",
                      select: {
                        href: "structureItem.href.toUrl",
                        label: "structureItem.label.value",
                      },
                    },
                    caption: "block.caption",
                    images: {
                      query: "block.images.toFiles",
                      select: {
                        url: true,
                      },
                    },
                  },
                },
                subpages: {
                  query: "page.children",
                  select: {
                    id: true,
                    title: true,
                    pagetype: true,
                    description: "page.text.kirbytext",
                    offerimg: "page.offerimg.toFile",
                    pagelink: true,
                    uri: true,
                    showinmenu: true,
                    slug: true,
                  },
                },
              },
            });

            // Füge die neuen Daten zu dem Content-Objekt hinzu
            const updatedElement = initialContent.find(
              (el) => el.slug === element.slug
            );
            if (updatedElement) {
              updatedElement.children = pageData.result.subpages;
              updatedElement.uri = pageData.result.uri;
            }
          }

          if (element.pagetype === "list") {
            const soalData = await fetchSOALData(element.resource);
            const kategorien = new Set();

            soalData.forEach((item) => {
              if (item[element.filtervariable] !== null) {
                kategorien.add(item[element.filtervariable]);
              }
            });

            // Füge die filterInMenu-Daten hinzu
            const updatedElement = initialContent.find(
              (res) => res.id === element.id
            );
            if (updatedElement) {
              updatedElement.filterInMenu = [...kategorien];
            }
          }
        });

        // Warte auf alle API-Aufrufe und aktualisiere das Content-Objekt
        await Promise.all(promises);
        setContent([...initialContent]); // Neue Kopie erzeugen, um eine Neurenderung zu erzwingen
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
      }
    };

    fetchData();
  }, [fetchKirbyData, fetchSOALData]);

  return (
    <div className="header--wrap hidden-print">
      <Search
        searchRef={search}
        searchBarOpen={searchBarOpen}
        setSearchBarOpen={setSearchBarOpen}
      />

      <div className="header--inner">
        <div className="header--logo">
          <Link to="/" className="header--logo-link">
            <svg className="inline-svg-icon" fill="none">
              <use xlinkHref="#logo"></use>
            </svg>
          </Link>
        </div>

        {content.length > 0 && <Menu content={content} />}

        <div className="header--icons-list">
          <a
            className="header--icons-list-item"
            href="//intern.soal.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="inline-svg-icon"
              style={{ enableBackground: "new 0 0 48 48" }}
              xmlSpace="preserve"
            >
              <use href="#soal_login"></use>
            </svg>
          </a>
          <Link to="/kontakt" className="header--icons-list-item">
            <svg
              className="inline-svg-icon"
              style={{ enableBackground: "new 0 0 48 48" }}
              xmlSpace="preserve"
            >
              <use href="#soal_contact"></use>
            </svg>
          </Link>
          <a
            href="https://www.instagram.com/soal.wohlfahrtsverband/"
            className="header--icons-list-item"
            target="_blank"
          >
            <svg
              className="inline-svg-icon"
              style={{ enableBackground: "new 0 0 48 48" }}
              xmlSpace="preserve"
            >
              <use href="#soal_instagram"></use>
            </svg>
          </a>
          <div className="header--icons-list-item" onClick={toggleSearch}>
            <svg
              className="inline-svg-icon"
              style={{ enableBackground: "new 0 0 48 48" }}
              xmlSpace="preserve"
            >
              <use className="stroke" href="#soal_search"></use>
            </svg>
          </div>
          <div className="header-mob--menu">
            <div className="header-mob--menu-icon" onClick={toggleMobMenu}>
              <svg className="inline-svg-icon" fill="none">
                <use href="#burger"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <MobileMenu
        content={content}
        show={showMob}
        toggleMobMenu={toggleMobMenu}
      />
    </div>
  );
};

export default withConsumer(Header);
